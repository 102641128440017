import React, { Component, ReactElement } from "react"

interface Props {
  icon?: ReactElement
  image: string
  title: string
  content: string | ReactElement
  contain?: boolean
  callToAction?: ReactElement
  background?: string
}

export default class Card extends Component<Props> {
  render() {
    return (
      <div
        className={`relative ${
          this.props.background ? this.props.background : "bg-white"
        } pt-16 pb-32 overflow-hidden`}
      >
        <div className="relative">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
            <div className="px-4 max-w-md sm:max-w-3xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
              <div>
                {this.props.icon ? (
                  <div>
                    <span className="h-12 w-12 rounded-md flex items-center justify-center bg-arusgreen-600">
                      {this.props.icon}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
                <div className="mt-6">
                  <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                    {this.props.title}
                  </h2>

                  {typeof this.props.content === "string" ? (
                    <p className="mt-4 text-base leading-7 text-gray-500 whitespace-pre-line">
                      {this.props.content}
                    </p>
                  ) : (
                    this.props.content
                  )}

                  {this.props.callToAction ? (
                    <div className="mt-6">{this.props.callToAction}</div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 lg:mt-0">
              <div className="pl-4 -mr-48 sm:pl-0 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                <img
                  className={`w-full sm:max-w-3xl ring-1 ring-black ring-opacity-5 ${
                    this.props.contain ? "" : "rounded-xl shadow-xl"
                  } lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none`}
                  src={this.props.image}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
